var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-block__box"},[_c('div',{staticClass:"module-block__cell"},[_c('div',{staticClass:"module-title"},[_vm._v("交易开关")]),_c('div',{staticClass:"page-home__btn"},[_c('van-button',{staticClass:"module-btn",attrs:{"color":"","type":"info"},on:{"click":function($event){return _vm.abiFun({ ..._vm.token['kaiqijiaoyi'], params: [true] })}}},[_vm._v(" 开 启 ")]),_c('van-button',{staticClass:"module-btn",attrs:{"color":"red","type":"info"},on:{"click":function($event){return _vm.abiFun({ ..._vm.token['kaiqijiaoyi'], params: [false] })}}},[_vm._v(" 关 闭 ")])],1)]),_c('div',{staticClass:"module-block__cell"},[_c('div',{staticClass:"module-title"},[_vm._v("设置白名单")]),_c('van-field',{staticClass:"module-input",attrs:{"clearable":"","placeholder":"钱包地址"},model:{value:(_vm.token['baimingdan'].params[0]),callback:function ($$v) {_vm.$set(_vm.token['baimingdan'].params, 0, $$v)},expression:"token['baimingdan'].params[0]"}}),_c('div',{staticClass:"page-home__btn"},[_c('van-button',{staticClass:"module-btn",attrs:{"color":"","type":"info"},on:{"click":function($event){return _vm.abiFun({
                ..._vm.token['baimingdan'],
                params: _vm.token['baimingdan'].params
            })}}},[_vm._v("设 置")])],1)],1),_c('div',{staticClass:"module-block__cell"},[_c('div',{staticClass:"module-title"},[_vm._v("设置4%手续费地址")]),_c('van-field',{staticClass:"module-input",attrs:{"clearable":"","placeholder":"钱包地址"},model:{value:(_vm.token['shouxufeidizhi4'].params[0]),callback:function ($$v) {_vm.$set(_vm.token['shouxufeidizhi4'].params, 0, $$v)},expression:"token['shouxufeidizhi4'].params[0]"}}),_c('div',{staticClass:"page-home__btn"},[_c('van-button',{staticClass:"module-btn",attrs:{"color":"","type":"info"},on:{"click":function($event){return _vm.abiFun({
                ..._vm.token['shouxufeidizhi4'],
                params: _vm.token['shouxufeidizhi4'].params
            })}}},[_vm._v("设 置")])],1)],1),_c('div',{staticClass:"module-block__cell"},[_c('div',{staticClass:"module-title"},[_vm._v("设置1%手续费地址")]),_c('van-field',{staticClass:"module-input",attrs:{"clearable":"","placeholder":"钱包地址"},model:{value:(_vm.token['shouxufeidizhi1'].params[0]),callback:function ($$v) {_vm.$set(_vm.token['shouxufeidizhi1'].params, 0, $$v)},expression:"token['shouxufeidizhi1'].params[0]"}}),_c('div',{staticClass:"page-home__btn"},[_c('van-button',{staticClass:"module-btn",attrs:{"color":"","type":"info"},on:{"click":function($event){return _vm.abiFun({
                ..._vm.token['shouxufeidizhi1'],
                params: _vm.token['shouxufeidizhi1'].params
            })}}},[_vm._v("设 置")])],1)],1),_c('div',{staticClass:"module-block__cell"},[_c('div',{staticClass:"module-title"},[_vm._v("转让权限")]),_c('van-field',{staticClass:"module-input",attrs:{"clearable":"","placeholder":"钱包地址"},model:{value:(_vm.token['zhuanrangquanxian'].params[0]),callback:function ($$v) {_vm.$set(_vm.token['zhuanrangquanxian'].params, 0, $$v)},expression:"token['zhuanrangquanxian'].params[0]"}}),_c('div',{staticClass:"page-home__btn"},[_c('van-button',{staticClass:"module-btn",attrs:{"color":"","type":"info"},on:{"click":function($event){return _vm.abiFun({
                ..._vm.token['zhuanrangquanxian'],
                params: _vm.token['zhuanrangquanxian'].params
            })}}},[_vm._v("设 置")])],1)],1),_c('div',{staticClass:"module-block__cell"},[_c('div',{staticClass:"module-title"},[_vm._v("丢弃权限")]),_c('div',{staticClass:"page-home__btn"},[_c('van-button',{staticClass:"module-btn",attrs:{"color":"","type":"info"},on:{"click":function($event){return _vm.abiFun({
                ..._vm.token['duiqiquanxian'],
                params: _vm.token['duiqiquanxian'].params
            })}}},[_vm._v("设 置")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }