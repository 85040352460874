<style lang="scss" scoped>
.Mystery-Box {
  margin-top: 20vh;
  padding-bottom: 100px;

  .page-home__btn {
    text-align: center;
    padding: 40px 0 10px;
  }

  .Mystery-Box__image {
    text-align: center;

    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
  }

  .module-block {
    height: auto;
    // height: 70vh;
    overflow-y: auto;
    padding: 2vh 2vw;
    background-color: rgba(0, 0, 0, 0.8);

    .module-block__cell {
      margin-bottom: 30px;

      .page-home__btn {
        display: flex;
        padding-top: 10px;
        justify-content: center;
      }

      .module-btn {
        margin: 0 10px;
      }
    }
  }

  .module-block__box {
    position: relative;
    padding-top: 20px;
  }

  .Mystery-Box__tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 14vh;
    left: 0px;
    z-index: 1;

    >div {
      width: 60px;
      line-height: 30px;
      text-align: center;
      // border-radius: 4px;
      overflow: hidden;
      background-color: #000;
      color: rgba(255, 255, 255, .5);
      border: 1px solid rgba(255, 255, 255, .5);
      transition: all .3s;

      &:nth-of-type(2) {
        margin-left: -2px;
      }

      &.active {
        color: #fff;
        background-color: rgba(255, 255, 255, .5);
      }
    }
  }

  .select-time {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: rgba(255, 255, 255, 0.5) !important;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    color: #323233;
    margin: 10px 0;
    text-align: center;
  }
}
</style>


<template>
  <div>
    <div class="module-block__box" style="margin-bottom: 20px;">



      <div class="module-block__cell">
        <div class="module-title">{{ mint['nftzhouzao'].label }}</div>

        <van-field class="module-input" v-model="mint['nftzhouzao'].params[1]" clearable
          :placeholder="mint['nftzhouzao'].placeholderItems[1]" />

        <van-field class="module-input" v-model="mint['nftzhouzao'].params[0]" clearable
          :placeholder="mint['nftzhouzao'].placeholderItems[0]" />

        <div style="text-align: center;margin-top: 10px;">
          <van-button class="module-btn" color="" type="info" @click="abiFunnftzhouzao">
            <span>
              设 置
            </span>
          </van-button>
        </div>
      </div>


      <div class="module-block__cell">
        <div class="module-title">{{ mint['zhixingquanzhongquanyi'].label }}</div>

        <div style="text-align: center;margin-top: 10px;">
          <van-button class="module-btn" color="" type="info" @click="abiFunzhixingquanzhongquanyi">
            <span>
              发 放
            </span>
          </van-button>
        </div>
      </div>

      <div class="module-block__cell">
        <div class="module-title">{{ mint['shezhibaimingban'].label }}</div>

        <van-field class="module-input" v-model="mint['shezhibaimingban'].params[0]" clearable
          :placeholder="mint['shezhibaimingban'].placeholderItems[0]" />

        <div style="display: flex;align-items: center; justify-content: end; font-size: 20px;">
          <span v-if="mint['shezhibaimingban'].params[1]">
            设定
          </span>
          <span v-else>
            取消
          </span>
          <van-switch v-model="mint['shezhibaimingban'].params[1]" style="margin-left: 10px;" />
        </div>

        <div style="text-align: center;margin-top: 10px;">
          <van-button class="module-btn" color="" type="info" @click="abiFunshezhibaimingban">
            <span>
              设 置
            </span>
          </van-button>
        </div>
      </div>
    </div>

    <div class="module-block__box">

      <div class="module-block__cell">
        <div class="module-title">{{ mint['tiqudaibidaoxinheyue'].label }}</div>

        <van-field class="module-input" :disabled="true" v-model="mint['tiqudaibidaoxinheyue'].params[0]" clearable
          :placeholder="mint['tiqudaibidaoxinheyue'].placeholderItems[0]" />

        <van-field class="module-input" :disabled="true" v-model="mint['tiqudaibidaoxinheyue'].params[1]" clearable
          :placeholder="mint['tiqudaibidaoxinheyue'].placeholderItems[1]" />


        <van-field class="module-input" type="number" v-model="mint['tiqudaibidaoxinheyue'].params[2]" clearable
          :placeholder="mint['tiqudaibidaoxinheyue'].placeholderItems[2]" />

        <div style="text-align: center;margin-top: 10px;">
          <van-button class="module-btn" color="" type="info" @click="tiqudaibidaoxinheyue">
            <span>
              设 置
            </span>
          </van-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { abi, ethers } from "../../contract-fun";

import contractWaKuang from '../../contractWaKuang'

export default {
  name: "Mystery-Box",
  data() {
    return {

      showPicker: false,

      minDate: new Date(2024, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: '',

      mint: {

        nftzhouzao: {
          label: 'NFT铸造',
          abiName: 'mintAdmin',
          contractKey: 'NFT',
          params: ['', ''],
          placeholderItems: ['请输入数量', '请输入钱包地址']
        },

        zhixingquanzhongquanyi: {
          label: '执行权重收益',
          abiName: 'adminRelease',
          contractKey: 'NFT',
          params: [],
          btnName: '发放',
          placeholderItems: ['请输入地址'],
          value: []
        },
        shezhibaimingban: {
          label: '设置白名单',
          abiName: 'setW',
          contractKey: 'NFT',
          params: ['', true],
          placeholderItems: ['请输入钱包地址']
        },

        tiqudaibidaoxinheyue: {
          label: '提取代币到新NFT合约',
          abiName: 'claimToken',
          contractKey: 'NFTOld',
          params: ['0x2d8ba55046B0Ee57A3c61Ea8C4dc1A4fBB375D73', '0x7fB6B736105b6e9333Fd0482a8d76D4D29F2B5C3', ''],
          placeholderItems: ['请输入数量', '请输入钱包地址']
        },
      },
    };
  },
  computed: {
    wallet() {
      return this.$store.state.wallet;
    }
  },
  methods: {

    tiqudaibidaoxinheyue() {
      const record = this.mint['tiqudaibidaoxinheyue']
      let params = record.params
      if(!params[2])return
      params[2] = typeof params[2] == 'string' ? (ethers.BigNumber.from(Number(params[2]) * 10000000000)).mul('100000000') : params[2]
      console.log('params', params);
      abi(
        {
          abiName: record.abiName,
          params,
          contractKey: record.contractKey
        },
        ({ code, data }) => {
          if (code == 200) {
            this.showSuccessMessage("操作成功!");
          } else {
            this.showSuccessMessage("取消操作!");
            this.$store.commit("SetLoading", false);
          }
        }
      );
    },


    abiFunnftzhouzao() {
      const record = this.mint['nftzhouzao']
      const params = record.params
      console.log('params', params);
      abi(
        {
          abiName: record.abiName,
          params,
          contractKey: record.contractKey
        },
        ({ code, data }) => {
          if (code == 200) {
            console.log(data)
            this.showSuccessMessage("操作成功!");
          } else {
            this.showSuccessMessage("取消操作!");
            this.$store.commit("SetLoading", false);
          }
        }
      );
    },

    abiFunzhixingquanzhongquanyi() {
      const record = this.mint['zhixingquanzhongquanyi']
      const params = record.params
      console.log('record.abiName', record);
      abi(
        {
          abiName: record.abiName,
          params,
          contractKey: record.contractKey,
        },
        ({ code, data }) => {
          if (code == 200) {
            this.showSuccessMessage("操作成功!");
          } else {
            this.showSuccessMessage("取消操作!");
            this.$store.commit("SetLoading", false);
          }
        }
      );
    },

    abiFunshezhibaimingban() {
      const record = this.mint['shezhibaimingban']
      const params = record.params
      console.log('record.abiName', record);
      abi(
        {
          abiName: record.abiName,
          params,
          contractKey: record.contractKey,
        },
        ({ code, data }) => {
          if (code == 200) {
            this.showSuccessMessage("操作成功!");
          } else {
            this.showSuccessMessage("取消操作!");
            this.$store.commit("SetLoading", false);
          }
        }
      );
    },

    abiFun(record, index) {
      if (!this.wallet) {
        this.showSuccessMessage("请先连接钱包!");
      } else {

        console.log(record.abiName)

        let params = record.params

        if (record.abiName == 'addPower' || record.abiName == 'delPower') {
          if (params[1] && params[1] > 0) {
            params[1] = this.filterNumber18(params[1])
          }
        } else if (record.abiName == 'setRewardFee') {
          params[0] = params[0] / 100
        } else if (record.abiName == 'claimToken') {
          params[2] = (ethers.BigNumber.from(Number(params[2]) * 10000000000)).mul('100000000')
        }

        console.log(params)
        abi(
          {
            abiName: record.abiName,
            params: params ? params : [],
            contractKey: record.contractKey
          },
          ({ code, data }) => {
            if (code == 200) {
              console.log(data)
              this.mint[index].value = data
              this.showSuccessMessage("操作成功!");
            } else {
              this.showSuccessMessage("取消操作!");
              this.$store.commit("SetLoading", false);
            }
          }
        );
      }
    },
    filterNumber18(data) {
      return (ethers.BigNumber.from(Number(data).toFixed(0))).mul('100000000000000000000')
    },
    filterNumber20(data) {
      return (ethers.BigNumber.from(Number(data).toFixed(0))).mul('10000000000000000000000')
    },
  },
};
</script>
