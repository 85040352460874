<template>
    <div class="Index">


        <div class="my-data module-block" :style="$global.moduleShowAnimation(1)">
            <div class="my-userData padding__10px font-color__white padding-top__20px module-have-btn">
                <div class="my-data__text display-flex__align-center font-size__15px padding-bottom__5px font-weight__700
                        padding-top__10px padding-bottom__10px position__relative">

                    <span class="font-color__theme font-family-Text2">
                        {{ $t(`wakuang1.wodezhanghao`) }}
                    </span>
                    <span class="padding-left__5px margin-right__5px font-family-Text2 font-size__12px">
                        /
                    </span>
                    <span class="font-color__theme-05 font-family-Text2 font-size__12px">
                        MY ACCOUNT
                    </span>


                    <div class="btn">
                        <div class="Share display-flex__align-center__justify-center font-color__theme-sub font-size__12px padding__5px padding-left__10px padding-right__10px"
                            @click="Share">
                            <img class="button-icon__small margin-right__5px" :src="iconList.shareIcon" alt="">
                            <span class="font-family-Text">{{ $t(`wakuang1.fengxiang`) }}</span>
                        </div>
                    </div>
                </div>
                <div class="padding-top__10px">
                    <div class="my-data__address background-image__default font-size__20px">
                        {{ $global.walletAddressALittle(wallet, 6) }}
                    </div>
                </div>
            </div>

            <div class="my-bind padding__10px font-color__theme" :style="$global.moduleShowAnimation(2)">
                <div class="box-shadow__theme-color display-flex__align-center__justify-around padding__10px"
                    style="line-height: 1;">
                    <div class="my-data__text display-flex__align-center font-weight__700">
                        <img class="button-icon" :src="iconList.catUserIcon" alt="">

                        <span class="padding-left__10px padding-right__10px">
                            {{ $t(`wakuang1.tuijianren`) }}
                        </span>
                    </div>
                    <div class="margin-right__10px">
                        <img :src="imageList.tuijianrenImage" alt="" style="height: 12px;">
                    </div>
                    <div class="" style="width: 130px;">
                        {{ $global.walletAddressALittle(bindAddress, 6) }}
                    </div>
                </div>
            </div>
        </div>


        <div class="my-list module-block module-block__bg2 position__relative module-radius"
            :style="$global.moduleShowAnimation(3)">
            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>{{ $t(`wakuang1.dengjijindu`) }}</div>
                <div></div>
            </div>

            <div class="my-userData padding__10px font-color__white padding-top__20px module-have-btn">
                <div class="padding-top__10px padding-bottom__10px">
                    <!-- 当前等级/地址团队业绩/下个等级需要的业绩 -->
                    <div class="display-flex__align-center__justify-between">
                        <div class="font-size__12px padding-bottom__10px">
                            {{ $t(`wakuang1.dangqiandengji`) }} v{{ singlePageConfig['dengji'].value[0] }}
                        </div>
                        <div class="font-size__12px padding-bottom__10px">
                            {{ $t(`wakuang1.haixu`) }} {{ singlePageConfig['dengji'].value[3] }}U
                        </div>
                    </div>
                    <van-progress style="width: 100%" :pivot-text="`${singlePageConfig['dengji'].value[1]}%`"
                        :percentage="singlePageConfig['dengji'].value[1] > 100 ? 100 : singlePageConfig['dengji'].value[1]"
                        stroke-width="8" />
                </div>


                <div class="display-flex__align-center__justify-center width__100per padding-top__10px">
                    <div class="button width__100per display-flex__align-center__justify-center font-weight__700"
                        @click="abiFun(pageBtnConfig['shengji'])">
                        <div class="font-color__theme-glod">
                            {{ $t(`wakuang1.shengji`) }}
                        </div>
                    </div>
                </div>

                
                <div class="display-flex__align-center__justify-center width__100per padding-top__10px">
                    <div class="button width__100per display-flex__align-center__justify-center font-weight__700"
                        @click="zhixingjiachi(pageBtnConfig['zhixingjiachi'])">
                        <div class="font-color__theme-glod">
                            执行加池
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="module-block module-block__bg2 position__relative module-radius font-weight__700"
            :style="$global.moduleShowAnimation(4)">
            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>{{ $t(`wakuang1.wodedaibi`) }}</div>
                <div></div>
            </div>

            <div
                class="font-color__white padding-top__20px shadow-none width__100per display-flex__align-center__justify-between">

                <div class="my-token__left">
                    <img :src="imageList.myTokenImage" alt="">
                </div>
                <div class="my-token__right">
                    <div class="padding-bottom__5px margin-top__10px">

                        <span class="module-block__title-2">
                            {{ $t(`wakuang1.wodeETF`) }}
                        </span>
                    </div>
                    <div class="des-diy-1">
                        <div class="font-color__grey">
                            {{ $t(`wakuang1.zongji`) }}
                        </div>
                        <div class="font-family-Number font-size__16px">
                            {{ pageConfig['wodeETFzongji'].value }}
                        </div>
                    </div>
                    <div class="des-diy-1">
                        <div class="font-color__grey">
                            {{ $t(`wakuang1.jiazhi`) }}
                        </div>
                        <div class="font-family-Number font-size__16px">
                            {{ pageConfig['wodeETFjiazhi'].value }}
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <splitLine></splitLine>

        <div class="my-des module-block module-block__bg4 module-block__bg2 position__relative
                padding-bottom__20px" :style="$global.moduleShowAnimation(5)">

            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>{{ $t(`wakuang1.wodekuangchi`) }}</div>
                <div></div>
            </div>

            <div class="padding-top__20px font-color__white width__100per module-radius margin-bottom__10px">
                <div class="des-diy-2">
                    <div class="padding__10px">
                        <img :src="imageList.suanliImage1" alt="">
                    </div>
                    <div class="padding__10px font-size__16px">
                        <div class="padding-bottom__5px font-weight__700">
                            <span class="font-color__white font-family-Text2">
                                {{ $t(`wakuang1.wodesuanli`) }}
                            </span>
                        </div>
                        <div class="font-family-Number font-color__theme">
                            {{ pageConfig['wodesuanli'].value }}
                        </div>
                    </div>
                </div>
                <div class="des-diy-2">
                    <div class="padding__10px">
                        <img :src="imageList.suanliImage2" alt="">
                    </div>
                    <div class="padding__10px font-size__16px">
                        <div class="padding-bottom__5px font-weight__700">
                            <span class="font-color__white font-family-Text2">
                                {{ $t(`wakuang1.yilingqujiangli`) }}
                            </span>
                        </div>
                        <div class="font-family-Number font-color__theme">
                            {{ pageConfig['yilingqujiangli'].value }} / {{ pageConfig['yilingqujiangli2'].value }} / {{
            pageConfig['yilingqujiangli3'].value }}
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="my-des-son module-block module-block__bg3 padding-top__20px padding-bottom__20px font-family-Text2">


                <div class="padding-bottom__5px font-weight__700 padding-left__10px">
                    {{ $t(`wakuang1.wodeshouyi`) }}
                </div>

                <div class="padding-bottom__10px display-flex__align-center__justify-around ">


                    <div class="des-diy-2">
                        <div class="padding__10px">
                            <img :src="imageList.suanliImage3" alt="">
                        </div>
                        <div class="padding__10px font-size__12px">
                            <div class="padding-bottom__5px font-weight__700">
                                <span class="font-color__white font-family-Text2 font-color__grey">
                                    {{ $t(`wakuang1.zongji`) }}
                                </span>
                            </div>
                            <div class="font-family-Number font-color__theme font-size__14px">
                                {{ pageConfig['wodeshouyizongji'].value }}
                            </div>
                        </div>
                    </div>

                    <div class="des-diy-2">
                        <div class="padding__10px">
                            <img :src="imageList.suanliImage4" alt="">
                        </div>
                        <div class="padding__10px font-size__12px">
                            <div class="padding-bottom__5px font-weight__700">
                                <span class="font-color__white font-family-Text2 font-color__grey">
                                    {{ $t(`wakuang1.jiazhi`) }}
                                </span>
                            </div>
                            <div class="font-family-Number font-color__theme font-size__14px">
                                {{ pageConfig['wodeshouyijiazhi'].value }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="padding-bottom__5px font-weight__700 padding-left__10px">
                    {{ $t(`wakuang1.jiasushouyi`) }}
                </div>
                <div class="padding-bottom__10px display-flex__align-center__justify-around ">

                    <div class="des-diy-2">
                        <div class="padding__10px">
                            <img :src="imageList.suanliImage5" alt="">
                        </div>
                        <div class="padding__10px font-size__12px">
                            <div class="padding-bottom__5px font-weight__700">
                                <span class="font-color__white font-family-Text2 font-color__grey">
                                    {{ $t(`wakuang1.zongji`) }}
                                </span>
                            </div>
                            <div class="font-family-Number font-color__theme font-size__14px">
                                {{ pageConfig['jiasushouyizongji'].value }}
                            </div>
                        </div>
                    </div>

                    <div class="des-diy-2">
                        <div class="padding__10px">
                            <img :src="imageList.suanliImage6" alt="">
                        </div>
                        <div class="padding__10px font-size__12px">
                            <div class="padding-bottom__5px font-weight__700">
                                <span class="font-color__white font-family-Text2 font-color__grey">
                                    {{ $t(`wakuang1.jiazhi`) }}
                                </span>
                            </div>
                            <div class="font-family-Number font-color__theme font-size__14px">
                                {{ pageConfig['jiasushouyijiazhi'].value }} ({{ pageConfig['jiasushouyiVIP'].value }})
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!singlePageConfig['shouyidongjieState'].value">

                    <div class="padding-bottom__5px font-weight__700 padding-left__10px">
                        {{ $t(`yilou.us44444`) }}
                    </div>

                    <div class="padding-bottom__10px display-flex__align-center__justify-around ">

                        <div class="des-diy-2">
                            <div class="padding__10px">
                                <img :src="imageList.suanliImage7" alt="">
                            </div>
                            <div class="padding__10px font-size__12px">
                                <div class="padding-bottom__5px font-weight__700">
                                    <span class="font-color__white font-family-Text2 font-color__grey">
                                        {{ $t(`wakuang1.zongji`) }}
                                    </span>
                                </div>
                                <div class="font-family-Number font-color__theme font-size__14px">
                                    {{ singlePageConfig['shouyidongjie'].value[1] }}
                                </div>
                            </div>
                        </div>

                        <div class="des-diy-2">
                            <div class="padding__10px">
                                <img :src="imageList.suanliImage8" alt="">
                            </div>
                            <div class="padding__10px font-size__12px">
                                <div class="padding-bottom__5px font-weight__700">
                                    <span class="font-color__white font-family-Text2 font-color__grey">
                                        {{ $t(`wakuang1.jiazhi`) }}
                                    </span>
                                </div>
                                <div class="font-family-Number font-color__theme font-size__14px">
                                    {{ singlePageConfig['shouyidongjie'].value[0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="display-flex__align-center__justify-center width__100per padding-top__10px">
                    <div class="button width__100per display-flex__align-center__justify-center font-weight__700"
                        @click="abiFun(pageBtnConfig['lingqujiangli'])">
                        <div class="font-color__theme-glod">
                            {{ $t(`wakuang1.ETFlingqu`) }}
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="module-block module-block__bg2 position__relative
                padding-bottom__20px" :style="$global.moduleShowAnimation(6)">

            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>{{ $t(`wakuang1.zongkuangchi`) }}</div>
                <div></div>
            </div>

            <div class="padding-top__10px font-color__white width__100per module-radius margin-bottom__20px">
                <div class="display-flex__align-center__justify-between">
                    <div class="des-diy-2">
                        <div class="padding__10px">
                            <img :src="imageList.suanliImage9" alt="">
                        </div>
                        <div class="padding__5px font-size__14px">
                            <div class="padding-bottom__5px font-weight__700">
                                <span class="font-color__white font-family-Text2">
                                    {{ $t(`wakuang1.zongji`) }}
                                </span>
                            </div>
                            <div class="font-family-Number font-color__theme">
                                {{ pageConfig['zongkuangchizongji'].value }}
                            </div>
                        </div>
                    </div>


                    <div class="des-diy-2">
                        <div class="padding__10px">
                            <img :src="imageList.suanliImage10" alt="">
                        </div>
                        <div class="padding__5px font-size__14px">
                            <div class="padding-bottom__5px font-weight__700">
                                <span class="font-color__white font-family-Text2">
                                    {{ $t(`wakuang1.jiazhi`) }}
                                </span>
                            </div>
                            <div class="font-family-Number font-color__theme">
                                {{ pageConfig['zongkuangchijiazhi'].value }}
                            </div>
                        </div>
                    </div>

                </div>



                <div
                    class="display-flex__align-center__justify-center margin-bottom__20px width__100per padding-top__10px">
                    <div class="button width__100per display-flex__align-center__justify-center font-weight__700"
                        @click="MintShow = true">
                        <div class="font-color__theme-glod">
                            {{ $t(`wakuang1.chongzhinengliang`) }}
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <splitLine></splitLine>


        <div class="my-list module-block module-block__bg2 position__relative module-radius"
            :style="$global.moduleShowAnimation(7)">

            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>{{ $t(`wakuang1.wodetuandui`) }}</div>
                <div></div>
            </div>
            <div class="padding__10px padding-top__20px">

                <van-row class="font-weight__700 font-color__theme-glod margin-bottom__5px">
                    <van-col span="9" class="padding-left__10px">
                        {{ $t(`wakuang1.zhishuxiashu`) }}
                    </van-col>
                    <van-col span="7" class="text-align__right">
                        {{ $t(`wakuang1.dengji`) }}
                    </van-col>
                    <van-col span="8" class="text-align__right">
                        {{ $t(`wakuang1.wodetuandui`) }}
                    </van-col>
                    <!-- <van-col span="5" class="text-align__right">
                            人数
                        </van-col> -->
                </van-row>

                <div v-if="pageListConfig['wodetuandui'].value[0].length > 0">
                    <van-row
                        class="font-size__12px margin-bottom__5px border-1px-solid-bottom border-color display-flex__align-center__justify-between padding-top__10px"
                        v-for="(item, index) of pageListConfig['wodetuandui'].value[0]" :key="index">

                        <van-col span="9">
                            {{ index + 1 }}. {{ $global.walletAddressALittle(item, 3) }}
                            <!-- <img class="button-icon button-icon__small" :src="iconList.copyIcon" @click="CopyAddress(item)"> -->
                        </van-col>


                        <van-col span="7" class="text-align__right">
                            v{{ pageListConfig['wodetuandui'].value[1][index] || 0 }}
                        </van-col>

                        <van-col span="8" class="text-align__right">
                            {{ $global.filterAccuracy18(pageListConfig['wodetuandui'].value[2][index]) }}
                        </van-col>

                        <!-- <van-col span="5" class="text-align__right">
                                {{ pageListConfig['wodetuandui'].value[3][index] || 0 }}
                            </van-col> -->
                    </van-row>
                </div>

                <div v-else>
                    -
                </div>
            </div>
        </div>



        <div class="my-list module-block module-block__bg2 position__relative module-radius"
            :style="$global.moduleShowAnimation(8)">
            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>{{ $t(`wakuang1.wodezhitui`) }}</div>
                <div></div>
            </div>
            <div class="padding__10px padding-top__20px">


                <van-row class="font-weight__700 margin-bottom__5px font-color__theme-glod">
                    <van-col span="10" class="padding-left__10px">
                        {{ $t(`wakuang1.dengji`) }}
                    </van-col>
                    <van-col span="7" class="text-align__right">
                        {{ $t(`wakuang1.renshu`) }}
                    </van-col>
                    <van-col span="7" class="text-align__right">
                        {{ $t(`wakuang1.jine`) }}
                    </van-col>
                </van-row>

                <div v-if="pageListConfig['wodezhitui'].value[0].length > 0">
                    <van-row
                        class="font-size__12px margin-bottom__5px border-1px-solid-bottom border-color padding-top__10px"
                        v-for="(item, index) of pageListConfig['wodezhitui'].value[0]" :key="index">

                        <van-col span="10">
                            {{ index + 1 }}. {{ $global.walletAddressALittle(item, 3) }}
                            <!-- <img class="button-icon button-icon__small" :src="iconList.copyIcon" @click="CopyAddress(item)"> -->
                        </van-col>


                        <van-col span="7" class="text-align__right">
                            v{{ pageListConfig['wodezhitui'].value[1][index] || 0 }}
                        </van-col>

                        <van-col span="7" class="text-align__right">
                            {{ $global.filterAccuracy18(pageListConfig['wodezhitui'].value[2][index]) }}
                        </van-col>
                    </van-row>
                </div>

                <div v-else>
                    -
                </div>
            </div>
        </div>



        <div class="my-list module-block module-block__bg2 position__relative module-radius"
            :style="$global.moduleShowAnimation(9)">
            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>{{ $t(`wakuang1.jianglimingxi`) }}</div>
                <div></div>
            </div>
            <div class="padding__10px padding-top__20px">



                <van-row class="font-weight__700 font-color__theme-glod margin-bottom__5px">
                    <van-col span="12" class="text-align__left">
                        {{ $t(`wakuang1.shuliang`) }}
                    </van-col>
                    <van-col span="12" class="text-align__right">
                        {{ $t(`wakuang1.shijian`) }}
                    </van-col>
                </van-row>

                <div v-if="singlePageConfig['jianglimingxi'].value.length > 0">
                    <van-row
                        class="font-size__12px margin-bottom__5px border-1px-solid-bottom border-color padding-top__10px"
                        v-for="(item, index) of singlePageConfig['jianglimingxi'].value" :key="index">

                        <van-col span="12" class="text-align__left">
                            {{ $global.filterAccuracy18(item.amount) }}
                        </van-col>

                        <van-col span="12" class="text-align__right">
                            {{ $global.utcTimestampToDate(item.time) }}
                        </van-col>
                    </van-row>
                </div>

                <div v-else>
                    -
                </div>
            </div>
        </div>



        <!-- <div class="display-flex__align-center__justify-center font-size__12px font-color__theme padding-top__50px">
            <img class="page-icon" :src="iconList.catWalkIcon" alt="" @click="JumpConfig">未完待续~
        </div> -->



        <!-- 
        <div class="module-block module-radius font-weight__700" :style="$global.moduleShowAnimation(3)">
            <div class="padding-bottom__10px">
                <Swap ref="Swap2" pageType="2" @close="$refs.Swap1.loadData()"></Swap>
            </div>
        </div> -->

        <!-- 
        <div class="module-block padding__10px module-radius font-weight__700" :style="$global.moduleShowAnimation(3)">
            <div class="padding-bottom__10px">
                <ConfigModule></ConfigModule>
            </div>
        </div> -->


        <!-- <splitLine></splitLine> -->



        <van-popup v-model="MintShow" position="bottom" :style="{ height: '85%' }">

            <div class="my-list module-block module-block__bg4 position__relative module-radius">
                <div class="module-block__title display-flex__align-center__justify-center">
                    <div></div>
                    <div>{{ $t(`wakuang1.chongzhinengliang`) }}</div>
                    <div></div>
                </div>

                <van-tabs type="card" color="#144f6c">

                    
                    
                    <van-tab v-if="false" title="LINE">
                        <div class="food-box">
                            <div v-for="(item, index) of ScheduleList" :key="index" class="food-cell padding__10px">
                                <div class="module-block width__100per module-radius 
                                padding__10px
                                text-align__center
                                font-color__white
                                font-size__16px
                                font-weight__700" @click="abiFunApproveUSDT(item, 1)">
                                    <img :src="item.icon" alt="">
                                    <!-- <span class="font-color__theme-glod">
                                    {{ item.label }}
                                </span> -->
                                </div>
                            </div>
                        </div>
                    </van-tab>

                    <van-tab title="Mining(1)">
                        <div class="food-box">
                            <div v-for="(item, index) of foodList" :key="index" class="food-cell padding__10px">
                                <div class="module-block width__100per module-radius 
                                padding__10px
                                text-align__center
                                font-color__white
                                font-size__16px
                                font-weight__700" @click="abiFunApproveUSDT(item, 1)">
                                    <img :src="item.icon" alt="">
                                    <!-- <span class="font-color__theme-glod">
                                    {{ item.label }}
                                </span> -->
                                </div>
                            </div>
                        </div>
                    </van-tab>

                    
                    <van-tab v-if="false" title="Mining(2)">
                        <div class="food-box">
                            <div v-for="(item, index) of foodList2" :key="index" class="food-cell padding__10px">
                                <div class="module-block width__100per module-radius 
                                    padding__10px
                                    text-align__center
                                    font-color__white
                                    font-size__16px
                                    font-weight__700" @click="abiFunApproveUSDT(item, 2)">
                                    <img :src="item.icon" alt="">
                                </div>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
                


                <!-- <div class="font-weight__700">
                充值能量-矿机2
            </div>
            <div class="food-box">
                <div v-for="(item, index) of foodList2" :key="index" class="food-cell padding__10px">
                    <div class="module-block width__100per module-radius background-color__theme 
                        padding__10px
                        text-align__center
                        font-color__white
                        font-size__16px
                        font-weight__700" @click="abiFunApproveUSDT(item, 2)">
                        
                        <span class="font-color__theme-glod">
                            {{ item.label }}
                        </span>
                    </div>
                </div>
            </div> -->
            </div>


        </van-popup>

        <div>

        </div>

    </div>
</template>

<style scoped lang="scss">
.Index {
    background-image: url(~@/assets/img/Mint/bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    width: 100%;
    height: 100vh;

    overflow-y: auto;
}

.module-have-btn {
    position: relative;

    .btn {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        color: #000;
        box-shadow: 0 0 10px rgba(36, 203, 198, .7);
        border-radius: 4px;

        .Share {
            background-color: transparent;
            box-shadow: inset 0 0 10px rgba(36, 203, 198, .7);
            border: 1px solid rgba(36, 203, 198, .5);
            border-radius: 4px;
        }
    }
}

::v-deep .van-tabs__wrap {
    position:relative;
    top: 30px;
}
</style>

<script>
import { approveToken, approveUSDT, allowanceUSDT, abi } from './js/contractMethods'
import contractToken from './js/contract'

import splitLine from "@/components/Line"

import catUserIcon from "@/assets/img/Mint/muzhi_icon.png"

import shareIcon from "@/assets/img/Mint/share_icon.png"

import catGlodIcon200 from "@/assets/img/Mint/200u.png"
import catGlodIcon500 from "@/assets/img/Mint/500u.png"
import catGlodIcon1000 from "@/assets/img/Mint/1000u.png"
import catGlodIcon3000 from "@/assets/img/Mint/3000u.png"
import catGlodIcon5000 from "@/assets/img/Mint/5000u.png"
import catGlodIcon10000 from "@/assets/img/Mint/10000u.png"

import tuijianrenImage from "@/assets/img/Mint/fenge_icon.png"
import myTokenImage from "@/assets/img/Home/gif/1_100.gif"

import suanliImage1 from "@/assets/img/Mint/suanli_icon.png"
import suanliImage2 from "@/assets/img/Mint/jiangli_icon.png"

import suanliImage3 from "@/assets/img/Mint/allsun_icon.png"
import suanliImage4 from "@/assets/img/Mint/jiazhi_icon.png"
import suanliImage5 from "@/assets/img/Mint/fastsun_icon.png"
import suanliImage6 from "@/assets/img/Mint/jiazhi_icon.png"

import suanliImage7 from "@/assets/img/Mint/suanli_icon.png"
import suanliImage8 from "@/assets/img/Mint/suanli_icon.png"

import suanliImage9 from "@/assets/img/Mint/zongkuangchi_icon.png"
import suanliImage10 from "@/assets/img/Mint/alljiazhi_icon.png"

import Swap from "@/views/Swap";
import ConfigModule from "@/views/Config";

export default {
    components: {
        splitLine,
        Swap,
        ConfigModule,
    },
    data() {
        return {
            address: "",
            approveState: false,

            bindAddressState: false,

            MintShow: false,

            topBindAddress: '0x924ecAD82FE497F6CD540Bd7Da2209C6B8678C86',


            iconList: {
                catUserIcon,
                shareIcon,
            },

            imageList: {
                tuijianrenImage,
                myTokenImage,
                suanliImage1,
                suanliImage2,
                suanliImage3,
                suanliImage4,
                suanliImage5,
                suanliImage6,
                suanliImage7,
                suanliImage8,
                suanliImage9,
                suanliImage10
            },

            foodList: [
                {
                    label: '200u',
                    abiName: 'pledgeU',
                    value: 200,
                    icon: catGlodIcon200
                },
                {
                    label: '500u',
                    abiName: 'pledgeU',
                    value: 500,
                    icon: catGlodIcon500
                },
                {
                    label: '1000u',
                    abiName: 'pledgeU',
                    value: 1000,
                    icon: catGlodIcon1000
                },
                {
                    label: '3000u',
                    abiName: 'pledgeU',
                    value: 3000,
                    icon: catGlodIcon3000
                },
                {
                    label: '5000u',
                    abiName: 'pledgeU',
                    value: 5000,
                    icon: catGlodIcon5000
                },
                {
                    label: '10000u',
                    abiName: 'pledgeU',
                    value: 10000,
                    icon: catGlodIcon10000
                },
            ],

            
            ScheduleList: [
                {
                    label: '200u',
                    abiName: 'pledgeUStandard',
                    value: 200,
                    icon: catGlodIcon200
                },
                {
                    label: '500u',
                    abiName: 'pledgeUStandard',
                    value: 500,
                    icon: catGlodIcon500
                },
                {
                    label: '1000u',
                    abiName: 'pledgeUStandard',
                    value: 1000,
                    icon: catGlodIcon1000
                },
                {
                    label: '3000u',
                    abiName: 'pledgeUStandard',
                    value: 3000,
                    icon: catGlodIcon3000
                },
                {
                    label: '5000u',
                    abiName: 'pledgeUStandard',
                    value: 5000,
                    icon: catGlodIcon5000
                },
                {
                    label: '10000u',
                    abiName: 'pledgeUStandard',
                    value: 10000,
                    icon: catGlodIcon10000
                },
            ],

            foodList2: [
                {
                    label: '200u',
                    abiName: 'pledgeToken',
                    value: 200,
                    icon: catGlodIcon200
                },
                {
                    label: '500u',
                    abiName: 'pledgeToken',
                    value: 500,
                    icon: catGlodIcon500
                },
                {
                    label: '1000u',
                    abiName: 'pledgeToken',
                    value: 1000,
                    icon: catGlodIcon1000
                },
                {
                    label: '3000u',
                    abiName: 'pledgeToken',
                    value: 3000,
                    icon: catGlodIcon3000
                },
                {
                    label: '5000u',
                    abiName: 'pledgeToken',
                    value: 5000,
                    icon: catGlodIcon5000
                },
                {
                    label: '10000u',
                    abiName: 'pledgeToken',
                    value: 10000,
                    icon: catGlodIcon10000
                },
            ],

            pageConfig: {
                shengyushengji: {
                    label: '剩余升级',
                    abiName: 'getUserZhiAmount',
                    value: 0
                },
                wodeETFzongji: {
                    label: '我的ETF总计',
                    abiName: 'getUserTokenBalance',
                    value: 0,
                    params: ['']
                },
                wodeETFjiazhi: {
                    label: '我的ETF价值',
                    abiName: 'getBalanceByU',
                    value: 0
                },


                wodesuanli: {
                    label: '我的算力',
                    abiName: 'getMaxByUser',
                    value: 0
                },
                yilingqujiangli: {
                    label: '已领取奖励',
                    abiName: 'getReceiveTokenAmountByU',
                    value: 0
                },
                yilingqujiangli2: {
                    label: '已领取奖励',
                    abiName: 'getUserReceivePool',
                    value: 0
                },
                yilingqujiangli3: {
                    label: '已领取奖励',
                    abiName: 'getUByOrder',
                    value: 0
                },

                wodeshouyizongji: {
                    label: '我的收益总计',
                    abiName: 'queryUserTokenReward',
                    value: 0
                },

                wodeshouyijiazhi: {
                    label: '我的收益价值',
                    abiName: 'queryUserUReward',
                    value: 0,
                },
                jiasushouyizongji: {
                    label: '加速收益总计',
                    abiName: 'queryEachTokenReward',
                    value: 0,
                },

                jiasushouyijiazhi: {
                    label: '加速收益价值',
                    abiName: 'queryEachUReward',
                    value: 0,
                },
                jiasushouyiVIP: {
                    label: '加速收益价值-VIP',
                    abiName: 'getVipRewardAmount',
                    value: 0,
                },
                zongkuangchizongji: {
                    label: '总矿池总计',
                    abiName: 'getSurplusReward',
                    value: 0,
                },

                zongkuangchijiazhi: {
                    label: '总矿池价值',
                    abiName: 'getRemainUByOrder',
                    value: 0,
                },
            },

            pageBtnConfig: {
                shengji: {
                    label: '升级',
                    abiName: 'grade',
                    btnText: 'yilou.us55555'
                },
                lingqujiangli: {
                    label: '领取奖励',
                    abiName: 'getReward',
                    btnText: 'yilou.us66666'
                },

                zhixingjiachi: {
                    label: '执行加池',
                    abiName: 'addLiquidityAllUsdt',
                },
            },

            pageListConfig: {
                wodetuandui: {
                    label: '我的团队',
                    abiNames: ['getTeamList', 'getUserLevel', 'getUserTotalAmount', 'getTeamAmount'],
                    params: [],
                    value: [
                        [], [], [], []
                    ]
                },
                wodezhitui: {
                    label: '我的直推',
                    abiNames: ['getTeamList', 'getUserLevel', 'getUserZhiAmount'],
                    params: [],
                    value: [
                        [], [], []
                    ]
                },

            },

            singlePageConfig: {
                jianglimingxi: {
                    label: '奖励明细',
                    abiName: 'getRewardList',
                    params: [],
                    value: []
                },
                dengji: {
                    label: '等级进度',
                    abiName: 'getLevelInfo',
                    params: [],
                    value: [0, 0, 0]
                },
                shouyidongjie: {
                    label: '我的收益(冻结)',
                    abiName: 'getFrozenInfo',
                    value: [0, 0]
                },
                shouyidongjieState: {
                    label: '我的收益(冻结)-状态',
                    abiName: 'getAccountStatus',
                    params: [],
                    value: false
                },
            }
        }
    },
    computed: {
        wallet() {
            // return this.topBindAddress
            return this.$store.state.wallet;
        },
        bindState() {
            return this.$store.state.bindState;
        },
        bindAddress() {
            return this.$store.state.bindAddress;
        },
        price() {
            /* U=可领取总量*0.0001 */
            return (this.pageConfig['kelingqujiangli'].value * 0.0001).toFixed(2)
        }
    },
    watch: {
        wallet(val) {
            if (val) {
                this.loadData()
            }
        }
    },
    created() {



        this.loadData()

        setTimeout(() => {
            this.testBindAddress()
        }, 500)

    },
    methods: {

        async testBindAddress() {


            const shareurl = this.$route.query.shareurl

            /* 
                1.有推荐人  推荐人
                2.没有推荐人  合约地址
                3.有shareurl 但shareurl没有推荐人
                4.无shareurl 但是自己有推荐人
            */
            // console.log('myAddress', this.wallet);
            const ongHaveBind = await this.testBind(this.wallet)
            console.log('ongHaveBind', ongHaveBind)

            if (ongHaveBind) {
                // console.log(1)
                this.$store.commit('SetBindAddress', ongHaveBind)
                this.$store.commit('SetBindState', true)
            } else {
                // console.log(2)
                if (shareurl) {

                    // console.log(3)
                    if (shareurl === this.wallet) {
                        // console.log(4)
                        this.$store.commit('SetBindAddress', this.topBindAddress)
                    } else {

                        const shareUrlHaveBind = await this.testBind(shareurl)
                        // console.log(5, 'shareUrlHaveBind', shareUrlHaveBind)
                        if (shareUrlHaveBind) {
                            // console.log(6)
                            this.$store.commit('SetBindAddress', shareurl)
                        } else {
                            // console.log(7)
                            this.$store.commit('SetBindAddress', this.topBindAddress)
                        }
                    }
                } else {
                    // console.log(8)
                    this.$store.commit('SetBindAddress', this.topBindAddress)
                }
            }
        },

        async loadData() {
            this.getLevelSchedule()


            this.$store.commit("SetLoading", false);
            const { pageConfig, pageListConfig, abi, abiList } = this

            const TokenAddress = await this.GetTokenAddress()

            this.pageConfig.wodeETFzongji.params[0] = TokenAddress


            for (let key in pageConfig) {
                abi(key, pageConfig[key])
            }


            const addressList = await this.getSonAddress()

            for (let key in pageListConfig) {

                this.pageListConfig[key].value[0] = addressList
                abiList(key, pageListConfig[key])
            }


            this.getAccountStatus()

            this.getRewardList()
        },

        JumpConfig() {
            this.$router.push("/Config")
        },

        /* 查询绑定人 */
        testBind(address) {

            const wallet = address
            return new Promise(resolve => {
                abi({
                    abiName: "getRecommend",
                    type: "check",
                    params: [wallet],
                },
                    async ({
                        code,
                        data,
                        message
                    }) => {
                        if (!data || data == '0x0000000000000000000000000000000000000000') {
                            resolve(false)
                        } else {
                            resolve(data)
                        }
                    }
                );
            })

        },

        // 分享
        Share() {
            
            if (!this.wallet) {
                this.showSuccessMessage(this.$i18n.t('tishi.us999'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'))
            } else {
                const url = window.location.href.split('#')[0]
                const param = this.wallet || ''
                const content = `${url}#/Mint?shareurl=${param}`
                var aux = document.createElement("input");
                aux.setAttribute("value", content);
                document.body.appendChild(aux);
                aux.select();
                document.execCommand("copy");
                document.body.removeChild(aux);
                this.showSuccessMessage(this.$i18n.t('tishi.us1111'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'))
            }
        },

        // 获取授权USDT地址
        GetUSDTAddress() {
            return new Promise(resolve => {
                abi(
                    {
                        abiName: "_USDT",
                        type: "check",
                        params: []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            // console.log('USDT地址:', data)
                            resolve(data)
                        }
                    }
                );
            })

        },


        // 获取授权Token地址
        GetTokenAddress() {
            return new Promise(resolve => {
                abi(
                    {
                        abiName: "_token",
                        type: "check",
                        params: []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            // console.log('授权token地址:', data)
                            resolve(data)
                        }
                    }
                );
            })

        },




        allowanceUSDT() {

            return new Promise(async resolve => {

                const USDTdAdress = await this.GetUSDTAddress()
                const allowanceUSDTNumber = await allowanceUSDT(USDTdAdress)

                resolve(this.$global.filterAccuracy18(allowanceUSDTNumber, 0) || 0)
            })
        },

        approveUSDT(usdt) {


            return new Promise(async (resolve) => {

                const allowanceUSDTNumber = await this.allowanceUSDT()
                // console.log('已经授权', Number(allowanceUSDTNumber));
                // console.log('此次需要授权', usdt);
                if (Number(allowanceUSDTNumber) >= Number(usdt)) {
                    resolve(true)
                } else {
                    this.$store.commit("SetLoadingText", this.$i18n.t('tishi.us22222'));
                    this.$store.commit("SetLoading", true);

                    const USDTdAdress = await this.GetUSDTAddress()
                    approveUSDT({
                        number: usdt,
                        address: USDTdAdress
                    }, (data) => {
                        if (data) {
                            this.approveState = true
                            resolve(true)
                        } else {
                            this.approveState = false
                            this.$store.commit("SetLoading", false);
                            this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.$store.commit("SetLoading", false);
                            resolve(false)
                        }
                    })
                }

            })

        },

        async approveToken() {

            this.$store.commit("SetLoadingText", this.$i18n.t('tishi.us22222'));
            this.$store.commit("SetLoading", true);


            const TokenAddress = await this.GetTokenAddress()

            return new Promise((resolve) => {
                approveToken({
                    address: TokenAddress
                }, (data) => {
                    if (data) {
                        this.approveState = true
                        resolve(true)
                    } else {
                        this.approveState = false
                        this.$store.commit("SetLoading", false);
                        this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                        this.$store.commit("SetLoading", false);
                        resolve(false)
                    }
                })
            })

        },

        abiList(key) {

            const record = this.pageListConfig[key]

            record.abiNames.forEach((abiName, index) => {

                if (index != 0) {
                    record.value[0].forEach((address, addressIndex) => {

                        abi(
                            {
                                abiName: abiName,
                                params: [address],
                                type: "check",
                            },
                            ({ code, data }) => {
                                if (code == 200) {

                                    const result = data || 0
                                    this.pageListConfig[key].value[index][addressIndex] = result

                                    const logMessage = `${record.label}(${abiName}): 地址 ${address}的${abiName}, 数据为${result}`
                                    // console.log(logMessage)
                                    this.$forceUpdate()
                                }
                            }
                        );
                    })
                }



            });


        },


        abi(key, record, notUseWallet) {

            let params = record.params && record.params.length > 0 ? [this.wallet, ...record.params] : [this.wallet]


            abi(
                {
                    abiName: record.abiName,
                    params: params,
                    type: "check",
                },
                ({ code, data }) => {
                    if (code == 200) {

                        let value = 0
                        if (this.pageConfig[key].valueKey) {
                            value = data[this.pageConfig[key].valueKey]
                        } else {
                            value = data
                        }
                        if (this.pageConfig[key].isNotNeed18) {
                            this.pageConfig[key].value = value == 0 ? 0 : (value);
                        } else {
                            this.pageConfig[key].value = value == 0 ? 0 : (value / (10 ** 18)).toFixed(2);
                        }

                        // console.log(record.label, "-", record.abiName, ":", this.pageConfig[key].value)
                        this.$forceUpdate()
                    }
                }
            );
        },

        Bind() {

            this.$store.commit("SetLoadingText", this.$i18n.t('tishi.us11111'));
            this.$store.commit("SetLoading", true);
            if (this.bindAddress === this.wallet) {

                this.showSuccessMessage(this.$i18n.t('tishi.us3333'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                this.$store.commit("SetLoading", false);
                resolve(false)
            } else {
                // console.log('this.bindState', this.bindState)
                return new Promise((resolve) => {
                    if (!this.bindState) {
                        abi({
                            abiName: 'bind',
                            params: [this.wallet, this.bindAddress]
                        }, ({ code, message }) => {
                            // console.log(code)
                            if (code == 200) {
                                this.$store.commit("SetBindState", true);
                                this.$store.commit("SetBindAddress", this.bindAddress);
                                resolve(true)
                            } else {
                                this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                                this.$store.commit("SetBindState", false);
                                this.$store.commit("SetLoading", false);
                                resolve(false)
                            }
                        })
                    } else {
                        resolve(true)
                    }
                })
            }
        },


        zhixingjiachi(record) {
            
            abi(
                    {
                        abiName: record.abiName,
                        params:  []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.showSuccessMessage('操作成功');
                            this.loadData()
                        } else {
                            this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.$store.commit("SetLoading", false);
                        }
                    }
                );
        },


        async abiFun(record) {
            if (!this.wallet) {
                this.showSuccessMessage(this.$i18n.t('tishi.us999'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
            } else {


                let status = await this.Bind(record.value)
                if (!status) return

                this.$store.commit("SetLoadingText", this.$i18n.t(`${record.btnText}`));
                this.$store.commit("SetLoading", true);

                abi(
                    {
                        abiName: record.abiName,
                        params: record.params ? record.params : [this.wallet]
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.loadData()
                        } else {
                            this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            this.$store.commit("SetLoading", false);
                        }
                    }
                );
            }
        },



        /* 先根据指示授权 再执行 */
        /* 1绑定2授权3私募 */
        async abiFunApproveUSDT(record, type) {

            let status = false
            if (!this.wallet) {

                this.showSuccessMessage(this.$i18n.t('tishi.us999'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
            } else {



                status = await this.Bind(record.value)
                if (!status) return

                if (type == 1) {
                    this.MintApprove1(record)
                } else {
                    this.MintApprove2(record)
                }
            }
        },


        // 矿机1
        async MintApprove1(record) {

            let status = false

            status = await this.approveUSDT(record.value)
            if (!status) return

            this.Mint(record)
        },

        async MintApprove2(record) {

            let status = false


            status = await this.approveToken()
            if (!status) return

            status = await this.approveUSDT(record.value)
            if (!status) return

            this.Mint(record)
        },


        // 矿机方法
        Mint(record) {

            this.$store.commit("SetLoadingText", this.$i18n.t('tishi.us33333'));
            const bigValue = (this.$ethers.BigNumber.from(record.value)).mul('1000000000000000000')
            abi(
                {
                    abiName: record.abiName,
                    params: [this.wallet, bigValue]
                },
                ({ code, data }) => {

                    if (code == 200) {
                        this.loadData()
                    } else {
                        this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                    }
                    this.MintShow = false
                    this.$store.commit("SetLoading", false);
                }
            );
        },


        // 获取直属下级
        getSonAddress() {
            return new Promise(resolve => {
                const abiName = this.pageListConfig.wodetuandui.abiNames[0]
                abi(
                    {
                        abiName,
                        params: [this.wallet],
                        type: 'check'
                    },
                    ({ code, data }) => {

                        if (code == 200) {
                            resolve(data)
                        } else {
                            resolve([])
                        }
                    }
                );
            })

        },


        // 检测冻结收益的显示状态
        getAccountStatus() {

            abi(
                {
                    abiName: 'getAccountStatus',
                    params: [this.wallet],
                    type: 'check'
                },
                ({ code, data }) => {

                    if (code == 200) {
                        // console.log('getAccountStatus', data)
                        this.singlePageConfig['shouyidongjieState'].value = data ? true : false
                        if (!data) {
                            this.getFreezeIncome()
                        }
                    } else {
                        this.singlePageConfig['shouyidongjieState'].value = false
                    }
                }
            );
        },

        // 查询冻结收益 shouyidongjie
        getFreezeIncome() {
            abi(
                {
                    abiName: 'getFrozenInfo',
                    params: [this.wallet],
                    type: 'check'
                },
                ({ code, data }) => {

                    if (code == 200) {
                        console.log('getFrozenInfo', data)
                        this.singlePageConfig.shouyidongjie.value = [this.$global.filterAccuracy18(data[0]) || 0, this.$global.filterAccuracy18(data[1]) || 0]
                    } else {
                    }
                }
            );
        },

        // 查询收益
        getRewardList() {
            abi(
                {
                    abiName: 'getRewardList',
                    params: [this.wallet],
                    type: 'check'
                },
                ({ code, data }) => {

                    if (code == 200) {
                        // console.log('getRewardList', data)
                        this.singlePageConfig.jianglimingxi.value = data
                    } else {
                    }
                }
            );
        },

        // 查询等级进度
        getLevelSchedule() {

            abi(
                {
                    abiName: this.singlePageConfig.dengji.abiName,
                    params: [this.wallet],
                    type: 'check'
                },
                ({ code, data }) => {

                    if (code == 200) {

                        // console.log('getLevellnfo', Number(data[0]))
                        // console.log('getLevellnfo', Number(data[1]))
                        // console.log('getLevellnfo', Number(data[2]))

                        const level = Number(data[0])
                        const currentPerformance = Number(this.$global.filterAccuracy18(data[1], 0))

                        const totalPerformance = Number(this.$global.filterAccuracy18(data[2], 0))
                        const remainingPerformance = totalPerformance <= 0 ? 0 : totalPerformance - currentPerformance

                        let percentage = totalPerformance <= 0 ? 100 : (currentPerformance / totalPerformance) * 100
                        percentage = percentage > 0 ? percentage.toFixed(2) : percentage

                        this.singlePageConfig.dengji.value = [level, percentage, totalPerformance, remainingPerformance]
                    } else {
                    }
                }
            );
        },

        // Up 升级
        Up() {

        }
    }
}
</script>

<style scoped lang="scss">
.Index {
    padding-top: 100px;
    padding-bottom: 100px;

    >div {
        margin-bottom: 10px;
    }

    .my-data__address {
        padding: 10px 20px;
        background-image: url(~@/assets/img/Mint/kuang_011.png);
    }

    .my-token__left {
        width: 40%;
        padding: 20px 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
            transform: scale(1.5) translateX(10px);
        }
    }

    .my-token__right {
        width: 55%;
        position: relative;
        // left: -10px;
    }

    .des-diy-1 {
        width: 100%;
        background-image: url(~@/assets/img/Mint/kuang_021.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 10px;
        display: flex;
        margin-top: 10px;
        align-items: center;
        justify-content: space-between;


    }

    .des-diy-2 {
        display: flex;
        align-items: center;

        >div:nth-of-type(1) {
            img {
                width: 40px;
                height: 40px;
            }
        }

        >div:nth-of-type(2) {
            width: calc(100% - 40px);
            padding: 5px!important;
            // background-image: url(~@/assets/img/Mint/suanli_bg.png);
            // background-size: 100% 100%;
            // background-repeat: no-repeat;
        }
    }


    .my-des {
        padding-bottom: 60px;

    }

    .my-des-son {
        .des-diy-2 {

            width: 50%;
            padding-left: 5px;

            >div:nth-of-type(1) {
                img {
                    width: 30px;
                    height: 30px;
                }
            }

            >div:nth-of-type(2) {
                width: calc(100% - 30px);
                padding-left: 5px !important;
                // background-image: url(~@/assets/img/Mint/suanli_bg.png);
                // background-size: 100% 100%;
                // background-repeat: no-repeat;
            }
        }
    }

    .my-list {
        padding: 40px 48px 60px;
    }

    ::v-deep .van-progress__portion,
    ::v-deep .van-progress__pivot {
        transition: all .3s;
    }

}
</style>