<style lang="scss" scoped>
.Mystery-Box {
  margin-top: 24vh;
  width: 90%;
  margin: 26vh auto;

  border-radius: 10px;

  box-shadow: 0px 0px 20px rgba(255,255,255,.3);

  overflow: hidden;


  .page-home__btn {
    text-align: center;
    padding: 40px 0 10px;
  }

  .Mystery-Box__image {
    text-align: center;

    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
  }

  .module-block {
    height: auto;
    // height: 70vh;
    overflow-y: auto;
    padding: 4vh 4vw;
    background-color: #9842;
    background-image: none;

    .module-block__cell {
      margin-bottom: 30px;

      .page-home__btn {
        display: flex;
        padding-top: 10px;
        justify-content: center;
      }

      .module-btn {
        margin: 0 10px;
      }
    }
  }

  .module-block__box {
    position: relative;
    padding-top: 20px;
  }

  .Mystery-Box__tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 18vh;
    left: 0px;
    z-index: 1;

    >div {
      width: 60px;
      line-height: 30px;
      text-align: center;
      // border-radius: 4px;
      overflow: hidden;
      background-color: #000;
      color: rgba(255, 255, 255, .5);
      border: 1px solid rgba(255, 255, 255, .5);
      transition: all .3s;

      &:nth-of-type(2) {
        margin-left: -2px;
      }

      &.active {
        color: #fff;
        background-color: rgba(255, 255, 255, .5);
      }
    }
  }
}
</style>


<template>
  <div class="Mystery-Box">
    <div class="module-block">
      <div class="Mystery-Box__tabs">
        <!-- <div class="NFT" :class="{ active: active == 0 }" @click="active = 0">NFT</div> -->
        <div class="Token" :class="{ active: active == 1 }" @click="active = 1">挖矿</div>
        <div class="SuperNode" :class="{ active: active == 2 }" @click="active = 2">超级节点</div>
      </div>

      <!-- <NFT v-if="active == 0"></NFT> -->

      <WaKunagModule v-if="active == 1"></WaKunagModule>

      <SuperNode v-else-if="active == 2"></SuperNode>
    </div>
  </div>
</template>

<script>

import TokenModule from './components/Token'
import WaKunagModule from './components/WaKuang'
import SuperNode from './components/SuperNode'
import NFT from './components/NFT'
export default {
  name: "Mystery-Box",
  components: {
    TokenModule,
    WaKunagModule,
    SuperNode,
    NFT
  },
  data() {
    return {
      active: 1,
    };
  },

};
</script>
