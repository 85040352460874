<style lang="scss" scoped>
.Mystery-Box {
    margin-top: 20vh;
    padding-bottom: 100px;

    .page-home__btn {
        text-align: center;
        padding: 40px 0 10px;
    }

    .Mystery-Box__image {
        text-align: center;

        img {
            width: 140px;
            height: 140px;
            object-fit: contain;
        }
    }

    .module-block {
        height: auto;
        // height: 70vh;
        overflow-y: auto;
        padding: 2vh 2vw;
        background-color: rgba(0, 0, 0, 0.8);

        .module-block__cell {
            margin-bottom: 30px;

            .page-home__btn {
                display: flex;
                padding-top: 10px;
                justify-content: center;
            }

            .module-btn {
                margin: 0 10px;
            }
        }
    }

    .module-block__box {
        position: relative;
        padding-top: 20px;
    }

    .Mystery-Box__tabs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 14vh;
        left: 0px;
        z-index: 1;

        >div {
            width: 60px;
            line-height: 30px;
            text-align: center;
            // border-radius: 4px;
            overflow: hidden;
            background-color: #000;
            color: rgba(255, 255, 255, .5);
            border: 1px solid rgba(255, 255, 255, .5);
            transition: all .3s;

            &:nth-of-type(2) {
                margin-left: -2px;
            }

            &.active {
                color: #fff;
                background-color: rgba(255, 255, 255, .5);
            }
        }
    }
}
</style>



<template>
    <div class="module-block__box">

        <div class="module-block__cell">
            <div class="module-title">交易开关</div>
            <div class="page-home__btn">
                <van-button class="module-btn" color="" type="info"
                    @click="abiFun({ ...token['kaiqijiaoyi'], params: [true] })">
                    开 启
                </van-button>
                <van-button class="module-btn" color="red" type="info"
                    @click="abiFun({ ...token['kaiqijiaoyi'], params: [false] })">
                    关 闭
                </van-button>
            </div>
        </div>

        
        <div class="module-block__cell">
            <div class="module-title">设置白名单</div>

            <van-field class="module-input" v-model="token['baimingdan'].params[0]" clearable
                placeholder="钱包地址" />
            <div class="page-home__btn">
                <van-button class="module-btn" color="" type="info" @click="abiFun({
                    ...token['baimingdan'],
                    params: token['baimingdan'].params
                })">设 置</van-button>
            </div>
        </div>
        

        <div class="module-block__cell">
            <div class="module-title">设置4%手续费地址</div>

            <van-field class="module-input" v-model="token['shouxufeidizhi4'].params[0]" clearable
                placeholder="钱包地址" />
            <div class="page-home__btn">
                <van-button class="module-btn" color="" type="info" @click="abiFun({
                    ...token['shouxufeidizhi4'],
                    params: token['shouxufeidizhi4'].params
                })">设 置</van-button>
            </div>
        </div>

        
        <div class="module-block__cell">
            <div class="module-title">设置1%手续费地址</div>

            <van-field class="module-input" v-model="token['shouxufeidizhi1'].params[0]" clearable
                placeholder="钱包地址" />
            <div class="page-home__btn">
                <van-button class="module-btn" color="" type="info" @click="abiFun({
                    ...token['shouxufeidizhi1'],
                    params: token['shouxufeidizhi1'].params
                })">设 置</van-button>
            </div>
        </div>

        

        
        <div class="module-block__cell">
            <div class="module-title">转让权限</div>

            <van-field class="module-input" v-model="token['zhuanrangquanxian'].params[0]" clearable
                placeholder="钱包地址" />
            <div class="page-home__btn">
                <van-button class="module-btn" color="" type="info" @click="abiFun({
                    ...token['zhuanrangquanxian'],
                    params: token['zhuanrangquanxian'].params
                })">设 置</van-button>
            </div>
        </div>

        
        <div class="module-block__cell">
            <div class="module-title">丢弃权限</div>

            <div class="page-home__btn">
                <van-button class="module-btn" color="" type="info" @click="abiFun({
                    ...token['duiqiquanxian'],
                    params: token['duiqiquanxian'].params
                })">设 置</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { abi, ethers } from "../../contract-fun";
import contractToken from '../../contractToken'

export default {
    name: "Mystery-Box",
    data() {
        return {

            token: {
                baimingdan: {
                    label: '设置白名单',
                    abiName: 'setWhiteList',
                    contractKey: 'Token',
                    params: ['', true,]
                },
                kaiqijiaoyi: {
                    label: '开启交易',
                    abiName: 'setSwapEnable',
                    contractKey: 'Token',
                    value: true
                },
                // shouxufeidizhi: {
                //     label: '设置5%手续费地址',
                //     abiName: 'setTargetAddress',
                //     contractKey: 'Token',
                //     params: ['']
                // },
                shouxufeidizhi4: {
                    label: '设置4%手续费地址',
                    abiName: 'setTargetAddress4',
                    contractKey: 'Token',
                    params: ['']
                },
                shouxufeidizhi1: {
                    label: '设置1%手续费地址',
                    abiName: 'setTargetAddress1',
                    contractKey: 'Token',
                    params: ['']
                },
                zhuanrangquanxian: {
                    label: '转让权限',
                    abiName: 'transferOwnership',
                    contractKey: 'Token',
                    params: ['']
                },
                duiqiquanxian: {
                    label: '丢弃权限',
                    abiName: 'renounceOwnership',
                    contractKey: 'Token',
                    params: []
                }
            },

        };
    },
    computed: {
        wallet() {
            return this.$store.state.wallet;
        }
    },
    methods: {

        filterNumber18(data) {
            return (ethers.BigNumber.from(Number(data).toFixed(0))).mul('1000000000000000000')
        },

        abiFun(record) {
            if (!this.wallet) {
                this.showSuccessMessage("请先连接钱包!");
            } else {

                abi(
                    {
                        abiName: record.abiName,
                        params: record.params ? record.params : [],
                        contractKey: record.contractKey
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            console.log(data)
                            this.showSuccessMessage("操作成功!");
                        } else {
                            this.showSuccessMessage("取消操作!");
                            this.$store.commit("SetLoading", false);
                        }
                    }
                );
            }
        },
    },
};
</script>
