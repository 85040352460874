<style lang="scss" scoped>
.Mystery-Box {
  margin-top: 20vh;
  padding-bottom: 100px;

  .page-home__btn {
    text-align: center;
    padding: 40px 0 10px;
  }

  .Mystery-Box__image {
    text-align: center;

    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
  }

  .module-block {
    height: auto;
    // height: 70vh;
    overflow-y: auto;
    padding: 2vh 2vw;
    background-color: rgba(0, 0, 0, 0.8);

    .module-block__cell {
      margin-bottom: 30px;

      .page-home__btn {
        display: flex;
        padding-top: 10px;
        justify-content: center;
      }

      .module-btn {
        margin: 0 10px;
      }
    }
  }

  .module-block__box {
    position: relative;
    padding-top: 20px;
  }

  .Mystery-Box__tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 14vh;
    left: 0px;
    z-index: 1;

    >div {
      width: 60px;
      line-height: 30px;
      text-align: center;
      // border-radius: 4px;
      overflow: hidden;
      background-color: #000;
      color: rgba(255, 255, 255, .5);
      border: 1px solid rgba(255, 255, 255, .5);
      transition: all .3s;

      &:nth-of-type(2) {
        margin-left: -2px;
      }

      &.active {
        color: #fff;
        background-color: rgba(255, 255, 255, .5);
      }
    }
  }

  .select-time {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: rgba(255, 255, 255, 0.5) !important;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    color: #323233;
    margin: 10px 0;
    text-align: center;
  }
}
</style>


<template>
  <div class="module-block__box">

    <div class="module-block__cell">
      <div class="module-title">{{ mint['shedingkuangji'].label }}</div>

      <van-field class="module-input" v-model="mint['shedingkuangji'].params[0]" clearable
        :placeholder="mint['shedingkuangji'].placeholderItems[0]" />

      <div style="display: flex;align-items: center; justify-content: end; font-size: 20px;">
        <span v-if="mint['shedingkuangji'].params[1]">
          设定
        </span>
        <span v-else>
          取消
        </span>
        <van-switch v-model="mint['shedingkuangji'].params[1]" style="margin-left: 10px;" />
      </div>

      <div style="text-align: center;margin-top: 10px;">
        <van-button class="module-btn" color="" type="info" @click="abiFunShedingkuangji">
          <span>
            设 置
          </span>
        </van-button>
      </div>
    </div>

    
    <div class="module-block__cell">
      <div class="module-title">{{ mint['chaxunxiaji'].label }}
        <div v-if="mint['chaxunxiaji'].value.length > 0" style="padding: 10px;color: #fff;">
            <div v-for="(record2, key2) of mint['chaxunxiaji'].value" :key="key2" style="padding: 10px;color: #fff;">
              {{ record2 }}
            </div>
        </div>

        <div v-else style="color: grey;text-align: center;font-size: 12px;padding: 30px 0;">
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { abi, ethers } from "../../contract-fun";

import contractWaKuang from '../../contractWaKuang'

export default {
  name: "Mystery-Box",
  data() {
    return {

      showPicker: false,

      minDate: new Date(2024, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: '',

      mint: {

        shedingkuangji: {
          label: '设置超级节点',
          abiName: 'setSuper',
          contractKey: 'SuperNode',
          params: ['', true],
          placeholderItems: ['请输入钱包地址']
        },

        chaxunxiaji: {
          label: '所有超级节点地址',
          abiName: 'getSuperList',
          contractKey: 'SuperNode',
          params: [],
          btnName: '获取',
          placeholderItems: ['请输入地址'],
          value: []
        },
      },
    };
  },
  computed: {
    wallet() {
      return this.$store.state.wallet;
    }
  },
  created() {
    this.abiFunChaxunxiaji()
  },
  methods: {

    filterNumber18(data) {
      return (ethers.BigNumber.from(Number(data).toFixed(0))).mul('100000000000000000000')
    },
    filterNumber20(data) {
      return (ethers.BigNumber.from(Number(data).toFixed(0))).mul('10000000000000000000000')
    },

    abiFunShedingkuangji() {
      const record = this.mint['shedingkuangji']
      const params = record.params
      console.log('record.abiName', record.abiName);
      abi(
        {
          abiName: record.abiName,
          params,
          contractKey: record.contractKey
        },
        ({ code, data }) => {
          if (code == 200) {
            console.log(data)
            this.mint[index].value = data
            this.abiFunChaxunxiaji()
            this.showSuccessMessage("操作成功!");
          } else {
            this.showSuccessMessage("取消操作!");
            this.$store.commit("SetLoading", false);
          }
        }
      );
    },

    abiFunChaxunxiaji() {
      const record = this.mint['chaxunxiaji']
      const params = record.params
      console.log('record.abiName', record);
      abi(
        {
          abiName: record.abiName,
          params,
          contractKey: record.contractKey,
          type: "check",
        },
        ({ code, data }) => {
          if (code == 200) {
            console.log(data)
            this.mint['chaxunxiaji'].value = data
          }
        }
      );
    },

    abiFun(record, index) {
      if (!this.wallet) {
        this.showSuccessMessage("请先连接钱包!");
      } else {

        console.log(record.abiName)

        let params = record.params

        if (record.abiName == 'addPower' || record.abiName == 'delPower') {
          if (params[1] && params[1] > 0) {
            params[1] = this.filterNumber18(params[1])
          }
        } else if (record.abiName == 'setRewardFee') {
          params[0] = params[0] / 100
        } else if (record.abiName == 'claimToken') {
          params[2] = (ethers.BigNumber.from(Number(params[2]) * 10000000000)).mul('100000000')
        }

        console.log(params)
        abi(
          {
            abiName: record.abiName,
            params: params ? params : [],
            contractKey: record.contractKey
          },
          ({ code, data }) => {
            if (code == 200) {
              console.log(data)
              this.mint[index].value = data
              this.showSuccessMessage("操作成功!");
            } else {
              this.showSuccessMessage("取消操作!");
              this.$store.commit("SetLoading", false);
            }
          }
        );
      }
    },
  },
};
</script>
