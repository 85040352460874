<style lang="scss" scoped>
.Mystery-Box {
  margin-top: 20vh;
  padding-bottom: 100px;

  .page-home__btn {
    text-align: center;
    padding: 40px 0 10px;
  }

  .Mystery-Box__image {
    text-align: center;

    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
  }

  .module-block {
    height: auto;
    // height: 70vh;
    overflow-y: auto;
    padding: 2vh 2vw;
    background-color: rgba(0, 0, 0, 0.8);

    .module-block__cell {
      margin-bottom: 30px;

      .page-home__btn {
        display: flex;
        padding-top: 10px;
        justify-content: center;
      }

      .module-btn {
        margin: 0 10px;
      }
    }
  }

  .module-block__box {
    position: relative;
    padding-top: 20px;
  }

  .Mystery-Box__tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 14vh;
    left: 0px;
    z-index: 1;

    >div {
      width: 60px;
      line-height: 30px;
      text-align: center;
      // border-radius: 4px;
      overflow: hidden;
      background-color: #000;
      color: rgba(255, 255, 255, .5);
      border: 1px solid rgba(255, 255, 255, .5);
      transition: all .3s;

      &:nth-of-type(2) {
        margin-left: -2px;
      }

      &.active {
        color: #fff;
        background-color: rgba(255, 255, 255, .5);
      }
    }
  }

  .select-time {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: rgba(255, 255, 255, 0.5) !important;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    color: #323233;
    margin: 10px 0;
    text-align: center;
  }
}
</style>


<template>
  <div class="module-block__box">




    <div class="module-block__cell" v-for="(item, index) of mint" :key="index">
      <div class="module-title">{{ item.label }}</div>



      <template v-if="item.abiName === 'setRewardFee'">
        <div>
          <van-row style="display: flex;align-items: center;">
            <van-col :span="8" style="display: flex;align-items: center;justify-content: end; padding-right: 10px;padding-top: 14px;">
              十万分之
            </van-col>
            <van-col :span="24 - 8">
              <span style="color: grey;font-size: 14px;">
                当前每小时收益率: {{ mint2['chanxundangqianmeixiaoshishouyi'].value }}%
              </span>
              <van-field class="module-input" type="number" style="width: 80%" v-model="item.params[0]" clearable
                :placeholder="item.placeholderItems[0]" />
            </van-col>
          </van-row>
        </div>
      </template>

      <template v-else-if="item.abiName === 'adminSetpledgeU'">

        <van-field class="module-input" v-model="item.params[0]" clearable :placeholder="item.placeholderItems[0]" />
        <van-field class="module-input" v-model="item.params[1]" clearable :placeholder="item.placeholderItems[1]" />

        <div class="select-time" @click="showPicker = true">
          <span v-if="currentDate">{{ currentDate }}</span>
          <span v-else>选择时间</span>
        </div>
        <van-field class="module-input" v-model="item.params[3]" clearable :placeholder="item.placeholderItems[3]" />

      </template>


      <template v-else>
        <van-field class="module-input" :type="item.inputType || 'string'" v-for="(record, key) of item.params"
          :key="key" v-model="item.params[key]" clearable :placeholder="item.placeholderItems[key]" />

      </template>

      <div class="page-home__btn">

        <van-button class="module-btn" color="" type="info" @click="abiFun({
          ...mint[index],
          params: mint[index].params
        }, index)">

          <span v-if="item.btnName">
            {{ item.btnName }}
          </span>
          <span v-else>
            设 置
          </span>
        </van-button>
      </div>
    </div>


    <div class="module-block__cell">
      <div class="module-title">{{ mint2['shezhiwuxiaozhanghu'].label }}</div>

      <van-field class="module-input" v-model="mint2['shezhiwuxiaozhanghu'].params[0]" clearable
        :placeholder="mint2['shezhiwuxiaozhanghu'].placeholderItems[0]" />

      <div style="display: flex;align-items: center; justify-content: end; font-size: 20px;">
        <span v-if="mint2['shezhiwuxiaozhanghu'].params[1]">
          有效
        </span>
        <span v-else>
          无效
        </span>
        <van-switch v-model="mint2['shezhiwuxiaozhanghu'].params[1]" style="margin-left: 10px;" />
      </div>

      <div style="text-align: center;margin-top: 10px;">
        <van-button class="module-btn" color="" type="info" @click="abiFunshezhiwuxiaozhanghu">
          <span>
            设 置
          </span>
        </van-button>
      </div>
    </div>


    <van-popup v-model:show="showPicker" round position="bottom">
      <van-datetime-picker v-model="mint['shedingkuangji'].params[2]" type="datetime" title="选择年月日" :min-date="minDate"
        :max-date="maxDate" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import { abi, ethers } from "../../contract-fun";

export default {
  name: "Mystery-Box",
  data() {
    return {

      showPicker: false,

      minDate: new Date(2024, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: '',

      mint2: {

        shezhiwuxiaozhanghu: {
          label: '设置无效账户',
          abiName: 'setOrderStatus',
          contractKey: 'MINT',
          params: ['', true],
          placeholderItems: ['请输入钱包地址']
        },


        chanxundangqianmeixiaoshishouyi: {
          label: '当前每小时收益率',
          abiName: '_baseRewardFee',
          contractKey: 'MINT',
          params: [],
          value: 0
        },


      },

      mint: {

        shezhimeixiaoshishouyilv: {
          label: '每小时收益率',
          abiName: 'setRewardFee',
          contractKey: 'MINT',
          params: [''],
          placeholderItems: ['请输入收益率']
        },
        shedingkuangji: {
          label: '设定矿机',
          abiName: 'adminSetpledgeU',
          contractKey: 'MINT',
          params: ['', '', '', ''],
          placeholderItems: ['请输入钱包地址', '请输入金额', '请选择时间', '请输入上级']
        },
        shedingdengji: {
          label: '设定等级',
          abiName: 'setLevel',
          contractKey: 'MINT',
          params: ['', ''],
          placeholderItems: ['请输入地址', '请输入等级']
        },

        tiquheyueneidaibi: {
          label: '提取合约内代币',
          abiName: 'withdraw',
          contractKey: 'MINT',
          params: ['', '', ''],
          placeholderItems: ['请输入代币合约地址', '请输入收款钱包地址', '请输入数量']
        },



        // kouchusuanli: {
        //   label: '管理员扣除算力',
        //   abiName: 'delPower',
        //   contractKey: 'MINT',
        //   params: ['', ''],
        //   placeholderItems: ['请输入地址', '请输入金额']
        // },
        // shezhishouyi: {
        //   label: '设置收益',
        //   abiName: 'setRewardFee',
        //   contractKey: 'MINT',
        //   inputType: 'number',
        //   params: [''],
        //   placeholderItems: ['请输入收益']
        // },
        // zhidingdizhi10: {
        //   label: '设置8%指定地址',
        //   abiName: 'setTargetAddress10',
        //   contractKey: 'MINT',
        //   params: [''],
        //   placeholderItems: ['请输入合约地址']
        // },
        // zhidingdizhi1: {
        //   label: '设置2%指定地址',
        //   abiName: 'setTargetAddress1',
        //   contractKey: 'MINT',
        //   params: [''],
        //   placeholderItems: ['请输入合约地址']
        // },
        // kaishishijian: {
        //   label: '设置项目开始时间',
        //   abiName: 'setStartTime',
        //   contractKey: 'MINT',
        //   params: [new Date()],
        //   placeholderItems: ['请输入下级地址']
        // },
        // bangdingguanxi: {
        //   label: '管理员绑定推荐关系',
        //   abiName: 'bindAdmin',
        //   contractKey: 'MINT',
        //   params: ['', ''],
        //   placeholderItems: ['请输入下级地址', '请输入上级地址']
        // },

        // chaxunshangji: {
        //   label: '查询上级',
        //   abiName: 'getRecommend',
        //   contractKey: 'MINT',
        //   params: [''],
        //   btnName: '获取',
        //   placeholderItems: ['请输入地址'],
        //   value: ''
        // },
        // chaxunxiaji: {
        //   label: '查询下级',
        //   abiName: 'getLow',
        //   contractKey: 'MINT',
        //   params: [''],
        //   btnName: '获取',
        //   placeholderItems: ['请输入地址'],
        //   value: []
        // },
      },
    };
  },
  computed: {
    wallet() {
      return this.$store.state.wallet;
    }
  },
  created() {
    this.abiFundangqianshouyilv()
  },
  methods: {

    onConfirm() {
      const dateStr = this.resetDateTimestamp(this.mint['shedingkuangji'].params[2])
      this.currentDate = this.resetDate(this.mint['shedingkuangji'].params[2])
      this.mint['shedingkuangji'].params[2] = dateStr
      this.showPicker = false
    },

    resetDate(str) {
      const dateStr = str;
      const date = new Date(dateStr);

      // 获取各个部分
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // 格式化为 "年-月-日 时:分:秒"
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return (formattedDate);
    },


    resetDateTimestamp(str) {
      const dateStr = str;
      const date = new Date(dateStr);

      // 获取时间戳（单位：毫秒），然后除以1000转为秒
      const timestampInSeconds = Math.floor(date.getTime() / 1000);
      return (timestampInSeconds);
    },


    filterNumber18(data) {
      return (ethers.BigNumber.from(Number(data).toFixed(0))).mul('100000000000000000000')
    },
    filterNumber20(data) {
      return (ethers.BigNumber.from(Number(data).toFixed(0))).mul('10000000000000000000000')
    },

    abiFun(record, index) {
      if (!this.wallet) {
        this.showSuccessMessage("请先连接钱包!");
      } else {

        console.log(record.abiName)

        let params = record.params

        if (record.abiName == 'adminSetpledgeU') {
          params[1] = (ethers.BigNumber.from(Number(params[1]) * 10000000000)).mul('100000000')
        } else if (record.abiName == 'tiquheyueneidaibi') {
          params[1] = (ethers.BigNumber.from(Number(params[2]) * 10000000000)).mul('100000000')
        }

        console.log(params)
        abi(
          {
            abiName: record.abiName,
            params: params ? params : [],
            contractKey: record.contractKey
          },
          ({ code, data }) => {
            if (code == 200) {
              console.log(data)
              // this.mint[index].value = data
              if(record.abiName == 'setRewardFee') {
                this.abiFundangqianshouyilv()
              }
              
              this.showSuccessMessage("操作成功!");
            } else {
              this.showSuccessMessage("取消操作!");
              this.$store.commit("SetLoading", false);
            }
          }
        );
      }
    },

    abiFundangqianshouyilv() {
      const record = this.mint2['chanxundangqianmeixiaoshishouyi']
      const params = record.params
      console.log('record.abiName', record.abiName);
      abi(
        {
          abiName: record.abiName,
          params,
          contractKey: record.contractKey,
          type: "check"
        },
        ({ code, data }) => {
          if (code == 200) {
            this.mint2['chanxundangqianmeixiaoshishouyi'].value = data && data > 0 ? data / ( 10 ** 3 ) : 0 
          } else {
            this.$store.commit("SetLoading", false);
          }
        }
      );
    },

    abiFunshezhiwuxiaozhanghu() {
      const record = this.mint2['shezhiwuxiaozhanghu']
      const params = record.params
      console.log('record.abiName', record.abiName);
      abi(
        {
          abiName: record.abiName,
          params,
          contractKey: record.contractKey
        },
        ({ code, data }) => {
          if (code == 200) {
            console.log(data)
            this.showSuccessMessage("操作成功!");
          } else {
            this.showSuccessMessage("取消操作!");
            this.$store.commit("SetLoading", false);
          }
        }
      );
    }
  },
};
</script>
